<template>
  <div class="grid grid-cols-12 bg-gray-50 p-4 border-b-2 border-gray-300 ">
    <div class="col-span-2"/>
    <div class="col-span-8 flex -mr-5 -ml-5">
    <span class="font-bold text-gray-500 ml-5 text-lg">Carlos Crane</span>
      <div class="flex-1"/>
      <a href="https://www.github.com/ccrama" class="text-gray-900 text-lg mr-5">GitHub</a>
      <a href="https://www.reddit.com/u/ccrama" class="text-gray-900 text-lg mr-5">Reddit</a>
    </div>
    <div class="col-span-2"/>
  </div>
</template>

<script>

export default {
  name: 'Nav',
  components: {
  }
}
</script>
